(function () {
	'use strict';

	angular
		.module('app')
		.factory('booking', [
			'bookingConfig',
			'$rootScope',
			'seedcodeCalendar',
			'calendarIO',
			'daybackIO',
			'manageSettings',
			'utilities',
			'environment',
			'manageFetch',
			booking,
		]);

	function booking(
		bookingConfig,
		$rootScope,
		seedcodeCalendar,
		calendarIO,
		daybackIO,
		manageSettings,
		utilities,
		environment,
		manageFetch
	) {
		var eventEditQueue = {};
		var eventCache = {};
		var isOffline;

		return {
			getConfig: getConfig,
			getFieldMap: getFieldMap,
			getUnusedMap: getUnusedMap,
			getAllowHTMLMap: getAllowHTMLMap,
			getHiddenFieldMap: getHiddenFieldMap,
			getReadOnlyFieldMap: getReadOnlyFieldMap,
			getAllowTextFieldMap: getAllowTextFieldMap,
			getEventEditQueue: getEventEditQueue,
			refresh: refresh,
			getSchedules: getSchedules,
			changeScheduleName: changeScheduleName,
			changeScheduleColor: changeScheduleColor,
			disableSchedule: disableSchedule,
			getEvents: getEvents,
			editEvent: editEvent,
			deleteEvent: deleteEvent,
		};

		function getConfig() {
			return bookingConfig.config();
		}

		function getFieldMap() {
			return bookingConfig.fieldMap();
		}

		function getUnusedMap() {
			return bookingConfig.unusedMap();
		}

		function getAllowHTMLMap() {
			return bookingConfig.allowHTMLMap();
		}
		function getHiddenFieldMap() {
			return bookingConfig.hiddenFieldMap();
		}
		function getReadOnlyFieldMap() {
			return bookingConfig.readOnlyFieldMap();
		}

		function getAllowTextFieldMap() {
			return false;
		}

		function getEventEditQueue() {
			return eventEditQueue;
		}

		function getConnection(schedule) {
			var user = daybackIO.getUser();

			var connection = {};
			return connection;
		}

		function refresh(schedule, callback) {
			callback();
		}

		function getSchedules(callback, sourceTemplate) {
			var config = seedcodeCalendar.get('config');
			var sources = seedcodeCalendar.get('sources');
			var schedules = [];
			var schedule;
			for (var i = 0; i < sources.length; i++) {
				if (sources[i].sourceTypeID === sourceTemplate.id) {
					//Apply our schedules
					schedule = setSchedule(sources[i]);
					schedules.push(schedule);
				}
			}

			callback(schedules, sourceTemplate);

			function setSchedule(schedule) {
				//Clean schedule data
				schedule.sourceID = schedule.id;
				calendarIO.cleanSchedule(schedule, sourceTemplate);
				return schedule;
			}
		}

		function changeScheduleName(name, callback, schedule) {
			manageSettings.setCalendarName(name, schedule, callback);
			return name;
		}

		function changeScheduleColor(color, callback, schedule) {
			manageSettings.setBackgroundColor(color, schedule, callback);
			return color;
		}

		function disableSchedule(scheduleID) {
			manageFetch.clear(scheduleID);
		}

		function getEvents(
			start,
			end,
			timezone,
			callbackFunc,
			schedule,
			options,
			eventID,
			fetchID,
			requestOverride
		) {
			//Event ID is optional to get a single event
			var fieldMap = schedule.fieldmap;
			var calendarElement = seedcodeCalendar.get('element');
			var config = seedcodeCalendar.get('config');
			var queryDate = calendarElement.fullCalendar('getDate');
			var eventList;

			var queryStart = moment(start).valueOf();
			var queryEnd = moment(end).valueOf();

			var callback = manageFetch.create(
				schedule.id,
				'event',
				callbackFunc
			).confirm;

			//If an eventID is passed in we use that
			if (eventID) {
				eventList = [eventID];
			}
			//Otherwise we check if there is a valid share where we can retrieve events from
			else if (schedule && schedule.share && schedule.share.events) {
				eventList = Object.keys(schedule.share.events);
			}
			if (eventList) {
				if (options.getSharedEvents || !eventCache[schedule.id]) {
					if (environment.isShare && isOffline) {
						firebase.database().goOnline();
						isOffline = false;
					}

					daybackIO.getEventData(
						'event',
						queryStart,
						queryEnd,
						eventList,
						processEvents,
						null
					);
				} else {
					//Wrap in a settimeout so we make sure this is async as we expect these calls to be
					window.setTimeout(function () {
						processEvents(eventCache[schedule.id]);
					}, 0);
				}
			} else {
				callback([]);
			}

			function processEvents(result) {
				var events;
				var message;

				eventCache[schedule.id] = JSON.parse(JSON.stringify(result));

				if (environment.isShare && !isOffline) {
					firebase.database().goOffline();
					isOffline = true;
				}

				//Notify on error
				if (result === false) {
					message = 'There was an error retrieving DayBack events';
					utilities.showMessage(
						message,
						0,
						8000,
						'error',
						null,
						true
					);
				} else if (
					(result && result.length > 1) ||
					(result && result.length === 1 && result[0])
				) {
					events = mutateEvents(result, schedule, fieldMap);
				}

				//callback is the built in full calendar event callback. This will write the events we just got to the view.
				callback(events);
			}
		}

		function customFieldMutateInput(item, fieldDefinition) {
			var result;
			if (fieldDefinition.formatas === 'select') {
				if (!item) {
					result = [];
				} else if (Array.isArray(item)) {
					result = item;
				} else {
					try {
						result = JSON.parse(item);
						if (!Array.isArray(result)) {
							result = [item];
						}
					} catch (error) {
						result = [item];
					}
				}
				calendarIO.replaceWithNoFilterValue(result);
			} else {
				result = item;
			}
			return result;
		}

		function customFieldMutateOutput(item, customField, schedule) {
			if (customField && customField.formatas === 'date') {
				return moment(item).format(schedule.fileDateFormat);
			} else if (customField && customField.formatas === 'timestamp') {
				return item
					? moment(item).format(schedule.fileTimestampFormat)
					: '';
			} else if (Array.isArray(item)) {
				calendarIO.removeNoFilterValue(item);
				return JSON.stringify(item);
			} else {
				return item;
			}
		}

		function mutateEvents(events, schedule, fieldMap) {
			const hookResult = calendarIO.mutateHook();
			if (!hookResult) {
				return [];
			}

			var outputEvent;
			var output = [];
			for (var event in events) {
				outputEvent = {};
				for (var property in events[event]) {
					outputEvent[property] = events[event][property];
				}
				//If the event is not set don't include in result
				if (outputEvent.eventID) {
					output.push(mutate(outputEvent));
				}
			}
			//Return our result
			return output;

			function mutate(event) {
				event.eventSource = schedule.id;
				event.schedule = schedule;

				//convert status to array
				if (event.status !== null && typeof event.status === 'object') {
					event.status = Object.values(event.status);
				}
				event.status =
					!event.status || event.status === ''
						? []
						: event.status.slice(0);

				//convert resource to array
				if (
					event.resource !== null &&
					typeof event.resource === 'object'
				) {
					event.resource = Object.values(event.resource);
				}
				event.resource =
					!event.resource || event.resource === ''
						? []
						: event.resource.slice(0);

				//convert contact to array
				event.contactName =
					!event.contactName || event.contactName === ''
						? []
						: event.contactName.slice(0);
				event.contactID =
					!event.contactID || event.contactID === ''
						? []
						: event.contactID.slice(0);

				//convert project to array
				event.projectName =
					!event.projectName || event.projectName === ''
						? []
						: event.projectName.slice(0);
				event.projectID =
					!event.projectID || event.projectID === ''
						? []
						: event.projectID.slice(0);

				// Apply timezone if necessary
				event.start = $.fullCalendar.createTimezoneTime(
					event.start,
					event.allDay
				);
				event.end = $.fullCalendar.createTimezoneTime(
					event.end,
					event.allDay
				);

				return calendarIO.cleanEvent(event);
			}
		}

		//Edit Events
		function editEvent(
			eventSet,
			revertObject,
			revertFunc,
			changeSet,
			editID,
			callback,
			schedule,
			progressCallback,
			depricatedEventID
		) {
			var config = seedcodeCalendar.get('config');
			var customFields = schedule ? schedule.customFields : null;
			var allDay;
			var editObjSet = [];
			var fieldMap;
			var source;
			var update;
			var editObj;
			var event;
			var changes;
			var share = schedule && schedule.share ? schedule.share : null;

			for (var i = 0; i < eventSet.length; i++) {
				editObj = {};
				event = eventSet[i];
				fieldMap = event.schedule.fieldMap;

				if (!changeSet || !changeSet[i]) {
					changes = {};
					for (var property in fieldMap) {
						changes[property] = event[property];
					}
				} else {
					changes = changeSet[i];
				}

				//Set allDay based on changes property if it exists
				allDay =
					changes && changes.hasOwnProperty('allDay')
						? changes.allDay
						: event.allDay;

				//Set source type id to event
				changes.sourceTypeID = event.sourceTypeID;
				changes.eventSource = event.eventSource;

				//Set colors - We do this here because shares are read only and we don't necessarily have source context for shared events
				changes.color = event.color;
				changes.borderColor = event.borderColor;
				changes.textColor = event.textColor;

				//Set isUnavailable for event
				changes.isUnavailable = event.isUnavailable;

				//Set Measure Only for event
				changes.isMeasureOnly = event.isMeasureOnly;

				//Set Map Only for event
				changes.isMapOnly = event.isMapOnly;

				//Set recid in changes so we know if it is a new event or not
				for (var property in changes) {
					//If the field is mapped and it has a change property, or if it is
					if (changes.hasOwnProperty(property)) {
						if (property === 'start') {
							editObj[property] = allDay
								? moment(changes[property]).format('YYYY-MM-DD')
								: $.fullCalendar
										.timezoneTimeToLocalTime(
											moment(changes[property]),
											allDay
										)
										.toISOString();
						} else if (property === 'end') {
							editObj[property] = allDay
								? moment(changes[property])
										.subtract(1, 'day')
										.format('YYYY-MM-DD')
								: $.fullCalendar
										.timezoneTimeToLocalTime(
											moment(changes[property]),
											allDay
										)
										.toISOString();
						}
						//Resources need to be called out so we can remove any stored "none" items and make them empty values
						else if (
							property === 'resource' ||
							property === 'status'
						) {
							editObj[property] = changes[property].slice(); //Using slice here to clone the array
							//Remove "none" label from resources so we don't write it back to the source
							for (
								var ii = 0;
								ii < editObj[property].length;
								ii++
							) {
								if (
									editObj[property][ii] ===
									config.noFilterLabel
								) {
									editObj[property][ii] = '';
								}
							}
						} else if (customFields && customFields[property]) {
							editObj[fieldMap[property]] =
								calendarIO.customFieldOutputTransform(
									property,
									changes[property],
									customFields,
									schedule,
									customFieldMutateOutput
								);
							//Adjust custom field data types to strings
							editObj[fieldMap[property]] =
								editObj[fieldMap[property]].toString();
						} else {
							editObj[property] = changes[property];
						}
					}
				}

				if (event.eventID) {
					update = true;
				} else {
					update = false;
					editObj.eventID = utilities.generateUID();
				}

				editObjSet.push(editObj);
			}

			daybackIO.setEvents(
				null,
				editObjSet,
				false,
				resultCallback,
				null,
				share,
				progressCallback,
				depricatedEventID
			);

			function resultCallback(result) {
				// var element = seedcodeCalendar.get('element');
				// var eventResult;
				// var message;

				// //If we are dragging this event currently we need to wait and see if the event has changed or not
				// if (eventEditQueue.dragging) {
				// 	window.setTimeout(function() {
				// 		resultCallback(result);
				// 	}, 250);
				// 	return;
				// }

				// //We are editing the same event before the last save so exit the save
				// if (editID && eventEditQueue[event.eventID] > editID) {
				// 	return;
				// }
				// else {
				// 	delete eventEditQueue[event.eventID];
				// }

				// //Check for errors and if we detect one revert the record
				// if (result[0] && result[0].ERRORCODE) {
				//   if (revertFunc) {
				//     message = "There was an error saving the event and your changes will be reverted: " + result[0].ERRORCODE + " - " + result[0].DESCRIPTION;
				// 		utilities.showModal('Operation Failed', message, null, null, 'Revert Changes', dragError);
				//   }
				//   else if (revertObject) {
				//     message = "There was an error and your event could not be saved: " + result[0].ERRORCODE + " - " + result[0].DESCRIPTION;
				// 		utilities.showModal('Operation Failed', message, 'Return To Event', cancelError, 'Revert Changes', confirmError);
				//   }
				//   return;
				// }
				//
				// //No errors found continue with updating the event with the returned data
				if (callback) {
					callback(result);
				}
			}
		}

		//Apply event changes returned from backend after an edit. Also returns wether the event was modified on the server
		function applyEventChanges(event, changes) {
			var modified;
			for (var property in changes) {
				//Check string, numbers, and booleans
				if (
					(typeof changes[property] === 'undefined' ||
						typeof changes[property] === 'string' ||
						typeof changes[property] === 'number' ||
						typeof changes[property] === 'boolean' ||
						(typeof changes[property] === 'object' &&
							changes[property] === null)) &&
					property !== 'dateStart' &&
					property !== 'dateEnd' &&
					property !== 'timeStart' &&
					property !== 'timeEnd'
				) {
					if (
						(event[property] === undefined ||
							event[property] === null ||
							event[property] === '') &&
						(changes[property] === undefined ||
							changes[property] === null ||
							changes[property] === '')
					) {
						// we have a null value so let's just set back to event to normalize but don't mark anything as modified
						event[property] = changes[property];
					} else if (event[property] !== changes[property]) {
						if (property !== 'eventID') {
							modified = true;
						}
						event[property] = changes[property];
					}
				}

				//Check arrays
				else if (Array.isArray(changes[property])) {
					if (
						!utilities.arraysEqual(
							event[property],
							changes[property]
						)
					) {
						modified = true;
						event[property] = changes[property];
					}
				}
				//Check moment objects
				else if (moment.isMoment(changes[property])) {
					if (!changes[property].isSame(event[property])) {
						modified = true;
						event[property] = changes[property];
					}
				}
			}
			return modified;
		}

		//Delete Event
		function deleteEvent(event, callback, schedule, depricatedEventID) {
			// daybackIO.setEvents('delete', editObjSet, false, processDelete);
			daybackIO.setEventData(
				null,
				utilities.generateEventID(
					event.sourceTypeID,
					event.eventSource,
					event.eventID,
					depricatedEventID
				),
				null,
				false,
				processDelete,
				null
			);

			function processDelete(result) {
				// var message;
				// if (result[0].ERRORCODE != 0) {
				//   message = "There was an error deleting the event: " + result[0].ERRORCODE + " - " + result[0].DESCRIPTION;
				// 	utilities.showModal('Operation Failed', message, null, null, 'Return To Event', confirmError);
				// 	return;
				// }
				// calendarIO.confirmDelete(event, result[0].ERRORCODE, callback);
				if (callback) {
					callback(result);
				}
			}
			function confirmError() {
				return;
			}
		}
	}
})();
